<template>
  <div>
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">MSRP Configuration</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-1" md="4">
        <b-form-group label="Original Market Specs" label-for="origenalMarket">
          <v-select
            v-model="purchaseQuoteData.origenalMarket"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'origenalMarket' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="purchaseQuoteData.origenalMarket"
            :reduce="(val) => val.id"
            :options="dropAll['origenalMarket']"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-1" md="4">
        <b-form-group label="Base MSRP Currency" label-for="msrpCurrency">
          <v-select
            v-model="purchaseQuoteData.msrpCurrency"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'msrpCurrency' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="purchaseQuoteData.msrpCurrency"
            :reduce="(val) => val.id"
            :options="dropAll['baseMsrpCurrency']"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Base MSRP" label-for="Base MSRP">
          <cleave id="baseMsrp" v-model="purchaseQuoteData.baseMsrp" class="form-control" :raw="true" :options="options.number" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12">
        <b-row>
          <b-col cols="12" md="6">
            <!--  <b-form-input v-model="msrpOption.title" id="msrpTitle" required placeholder="Msrp Options" /> !-->
            <b-form-group label="Msrp Options Name" label-for="msrp-option">
              <v-select
                v-model="msrpValueOption"
                :taggable="true"
                :create-option="(temp) => ({ id: 0, option: temp, who: 'msrpValueOptions' })"
                :loading="dropdownLoading"
                @option:created="createNewDropdownValue"
                @option:selected="selectedDropdownValue"
                :value="msrpValueOption"
                @input="msrpMakeSelected(msrpValueOption)"
                :reduce="(val) => val.id"
                :options="msrpValueOptions"
                label="option"
                :disabled="!(purchaseQuoteData.make > 0 && purchaseQuoteData.model > 0)"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="10" md="5">
            <b-form-group label="Msrp Options Price" label-for="msrp-price">
              <v-select
                v-model="msrpPriceOption"
                :taggable="true"
                :create-option="(temp) => ({ id: 0, price: temp, who: 'msrpPriceOptions' })"
                :loading="dropdownLoading"
                @option:created="createNewDropdownValue"
                @option:selected="selectedDropdownValue"
                :value="msrpPriceOptions.title"
                :reduce="(val) => val.id"
                :options="msrpPriceOptions"
                label="price"
                :disabled="!msrpValueOption > 0"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="1" md="1" class="text-center">
            <b-form-group label="#" label-for="addMsrp">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="!msrpPriceOption" @click="addMsrp()" input-id="addMsrp" class="btn-icon text-right " variant="primary">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" md="12" class="pt-2 mb-5">
        <table class="table border table-sm" v-if="purchaseQuoteData.msrpOptions.length > 0">
          <thead>
            <tr>
              <th scope="col" class="text-left">Option name</th>
              <th scope="col" class=" text-center">Option Price</th>
              <th scope="col" class="text-center" style="width:10%">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in purchaseQuoteData.msrpOptions" v-bind:key="item.index">
              <td>{{ item.title }}</td>
              <td class="text-center">{{ formatPrice(item.price) }}</td>

              <td class="text-center"><feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeOptionItem(index)" size="16" class="cursor-pointer mx-1" /></td>
            </tr>

            <td class="bg-secondary"></td>
            <td class="bg-secondary"></td>
            <td class="bg-secondary"></td>

            <tr class="mt-5">
              <td class="text-right font-weight-bold">Total MSRP Options Price</td>
              <td class="text-center">{{ msrpOptionTotal }}</td>
              <td></td>
            </tr>

            <tr>
              <td class="text-right font-weight-bold">Total MSRP</td>
              <td class="text-center">{{ msrpTotal }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <b-alert variant="warning" :show="purchaseQuoteData.msrpOptions == null || purchaseQuoteData.msrpOptions == ''">
          <div class="alert-body">
            <span
              ><strong><center>Your Msrp Options is empty.</center></strong>
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton, BAlert } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,

    vSelect,
    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },

    msrpOptions: {
      type: Array,
      required: false,
    },

    dropAll: {
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,

      modelOptionsVal: [],
      trimOptionsVal: [],
      msrpValueOption: null,
      msrpPriceOption: null,
      msrpValueOptions: [],
      msrpPriceOptions: [],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'purchaseQuoteData.model': {
      handler: function(id, before) {
        if (id != null) {
          this.msrpClick();
        } else {
          this.purchaseQuoteData.msrpOptions = [];
          this.$emit('update:msrpOptions', []);
        }
      },
    },
  },

  computed: {
    msrpOptionTotal() {
      var total = 0;
      this.purchaseQuoteData.msrpOptions.forEach((element) => {
        total = total + element.price;
      });
      return this.formatPrice(total);
    },

    msrpTotal() {
      var total = 0;
      this.purchaseQuoteData.msrpOptions.forEach((element) => {
        total = total + element.price;
      });
      return this.formatPrice(Number(total) + Number(this.purchaseQuoteData.baseMsrp));
    },
  },

  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      switch (val.who) {
        case 'origenalMarket':
          if (this.dropAll['origenalMarket'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveOrigenalMarket', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['origenalMarket'].unshift(response.data);
                this.purchaseQuoteData.origenalMarket = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpCurrency':
          if (this.dropAll['baseMsrpCurrency'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveBaseMsrpCurrency', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['baseMsrpCurrency'].unshift(response.data);
                this.purchaseQuoteData.msrpCurrency = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpValueOptions':
          if (this.msrpValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveMsrpOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.purchaseQuoteData.make,
                modelId: this.purchaseQuoteData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.msrpValueOptions.unshift(response.data);
                  this.msrpValueOption = response.data.id;
                  this.toastMessage('Msrp Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpPriceOptions':
          if (val.price >= 0) {
            if (this.msrpPriceOptions.find((x) => x.price == val.price) == undefined) {
              store
                .dispatch('purchase/saveMsrpPrice', {
                  price: val.price.trim(),
                  optionId: this.msrpValueOption,
                })
                .then((response) => {
                  if (response.status == 201) {
                    this.msrpPriceOptions.unshift(response.data);
                    this.msrpPriceOption = response.data.id;
                    this.toastMessage('Msrp Options Price');
                    this.dropdownLoading = false;
                  }
                });
            } else {
              this.dropdownLoading = false;
            }
          } else {
            this.dropdownLoading = false;
            this.msrpPriceOption = null;
          }
          break;

        default:
          break;
      }
    },

    selectedDropdownValue(val) {
      switch (val.who) {
        case 'origenalMarket':
          this.purchaseQuoteData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.origenalMarket != undefined && this.purchaseQuoteData.origenalMarket != 0) {
            this.purchaseQuoteData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpCurrency':
          this.purchaseQuoteData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.msrpCurrency != undefined && this.purchaseQuoteData.msrpCurrency != 0) {
            this.purchaseQuoteData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpValueOptions':
          this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          if (this.msrpValueOption != undefined && this.msrpValueOption != 0) {
            this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0].id;
          }

          this.msrpMakeSelected(this.msrpValueOption);

          break;

        case 'msrpPriceOptions':
          this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0];

          if (this.msrpPriceOption != undefined && this.msrpPriceOption != 0) {
            this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0].id;
          }

          break;

        default:
          break;
      }
    },

    msrpClick() {
      if (this.purchaseQuoteData.make) {
        var a = this.purchaseQuoteData.make.id;
        var b = this.purchaseQuoteData.model.id;
      }

      if (this.purchaseQuoteData.make.id == undefined) {
        a = this.purchaseQuoteData.make;
      }
      if (this.purchaseQuoteData.model.id == undefined) {
        b = this.purchaseQuoteData.model;
      }

      store
        .dispatch('purchase/getMsrpOption')
        .then((response) => {
          this.msrpValueOptions = response.data.filter((x) => x.makeId == a && x.modelId == b);
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching price list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    msrpMakeSelected(k) {
      this.msrpPriceOption = null;
      if (k != null && k != 0 && k != undefined) {
        this.dropdownLoading = true;
        store
          .dispatch('purchase/getMsrpPrice')
          .then((response) => {
            this.msrpPriceOptions = response.data.filter((x) => x.optionId == k);
            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching price list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    addMsrp() {
      this.msrpOptions.push({
        title: this.msrpValueOptions.filter((x) => x.id == this.msrpValueOption)[0].option,
        price: this.msrpPriceOptions.filter((x) => x.id == this.msrpPriceOption)[0].price,
        modelId: this.purchaseQuoteData.make,
        makeId: this.purchaseQuoteData.model,
      });

      this.purchaseQuoteData.msrpOptions = this.msrpOptions;

      this.msrpPriceOption = null;
      this.msrpValueOption = null;
    },

    removeOptionItem(e) {
      this.$delete(this.msrpOptions, e);
      this.purchaseQuoteData.msrpOptions = this.msrpOptions;
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
