<template>
  <div>
    <b-overlay :show="formShow" rounded="sm" no-fade>
      <form-wizard color="#3178cf" ref="wizard" scope="props" :title="null" :subtitle="null" shape="square" layout="vertical" finish-button-text="Save" back-button-text="Previous" class="vertical-steps steps-transparent" @on-complete="formSubmitted">
        <tab-content title="Description of Vehicle" icon="feather icon-file-text">
          <description-of-vehicle :dropInventorys.sync="dropInventorys" :invInfo.sync="invInfo" :formShow.sync="formShow" :purchaseQuoteData.sync="purchaseQuoteData" :dropAll.sync="dropAll" ref="subDescriptionComponent"></description-of-vehicle>
        </tab-content>

        <!-- <tab-content title="MSRP Configuration" icon="feather icon-file-text">
          <msrp-configuration :purchaseQuoteData.sync="purchaseQuoteData" :msrpOptions.sync="msrpOptions" :dropAll.sync="dropAll"></msrp-configuration>
        </tab-content> -->

        <!-- <tab-content title="Accessories Registration" icon="feather icon-file-text">
          <accessories-registration :purchaseQuoteData.sync="purchaseQuoteData" :accessioreOption.sync="accessioreOption" :dropAll.sync="dropAll"></accessories-registration>
        </tab-content> -->

        <!-- <tab-content title="Vehicle Declearation" icon="feather icon-file-text">
          <vehicle-declearation :purchaseQuoteData.sync="purchaseQuoteData" ref="subDeclearationComponent"></vehicle-declearation>
        </tab-content> -->

        <tab-content title="Vehicle Appraisal Photos" icon="feather icon-file-text">
          <vehicle-gallery :purchaseQuoteData.sync="purchaseQuoteData"></vehicle-gallery>
        </tab-content>
        <!-- 
        <tab-content title="Vehicle Appraisal & Lien" icon="feather icon-file-text">
          <vehicle-appraisal :purchaseQuoteData.sync="purchaseQuoteData" ref="subAppraisalComponent"></vehicle-appraisal>
        </tab-content> -->
        <!-- <tab-content title="Lien Holder Identification" icon="feather icon-file-text" v-if="purchaseQuoteData.vehicleTitleType != 1">
          <lien-holder :purchaseQuoteData.sync="purchaseQuoteData" :dropCustomers.sync="dropCustomers" :lienCustomerData.sync="lienCustomerData" ref="subLienComponent"> </lien-holder>
        </tab-content> -->

        <!-- <tab-content title="Seller Identification" icon="feather icon-file-text">
          <seller-identification :purchaseQuoteData.sync="purchaseQuoteData" :dropCustomers.sync="dropCustomers" :sellerCustomerData.sync="sellerCustomerData" ref="subSellerComponent"></seller-identification>
        </tab-content> -->

        <!-- <tab-content title="Co-Seller" icon="feather icon-file-text">
          <co-owner :purchaseQuoteData.sync="purchaseQuoteData" :dropCustomers.sync="dropCustomers" :coOwnerCustomerDatas.sync="coOwnerCustomerDatas" ref="subCoOwnerComponent"></co-owner>
        </tab-content> -->

        <tab-content title="Price Calculation" icon="feather icon-file-text">
          <price-calculation :purchaseQuoteData.sync="purchaseQuoteData" ref="subPriceCalculation"></price-calculation>
        </tab-content>

        <tab-content title="Comments & Others" icon="feather icon-file-text">
          <comments-others :purchaseQuoteData.sync="purchaseQuoteData" ref="subCommentsComponent"></comments-others>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { BContainer, BOverlay } from 'bootstrap-vue';

import testleaseStoreModule from '@/views/testlease/testleaseStoreModule';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import router from '@/router';

import { required, email } from '@validations';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DescriptionOfVehicle from './DescriptionOfVehicle.vue';
import MsrpConfiguration from './MsrpConfiguration.vue';
import AccessoriesRegistration from './AccessoriesRegistration.vue';
import VehicleDeclearation from './VehicleDeclearation.vue';
import VehicleAppraisal from './VehicleAppraisal.vue';
import VehicleGallery from './VehicleGallery.vue';
import LienHolder from './LienHolder.vue';
import SellerIdentification from './SellerIdentification.vue';
import CoOwner from './CoOwner.vue';
import PriceCalculation from './PriceCalculation.vue';
import CommentsOthers from './CommentsOthers.vue';

export default {
  components: {
    BContainer,
    BOverlay,
    FormWizard,
    TabContent,
    WizardButton,

    required,
    email,

    //TabPage
    DescriptionOfVehicle,
    MsrpConfiguration,
    AccessoriesRegistration,
    VehicleDeclearation,
    VehicleAppraisal,
    VehicleGallery,
    LienHolder,
    SellerIdentification,
    CoOwner,
    PriceCalculation,
    CommentsOthers,
  },
  directives: {
    Ripple,
  },

  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'testlease';
    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, testleaseStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },

  watch: {
    'purchaseQuoteData.inventoryId': {
      handler: function(x, y) {
        if (y != undefined) {
          this.clearInventoryData();
        }
        if(!router.currentRoute.params.id){
          this.getInventoryDropId(x);

        }
        else{
          this.getInventoryDropId(this.purchaseQuoteData.vehicleId)
        }
      },
      deep: true,
    },
  },

  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
  },

  created() {
    this.getAllDropDown();
    // this.getInventoryDrops();
    // this.getCustomerDrops();
    this.getByIdQuote();
  },

  data() {
    var purchaseQuoteDataModel = {
      inceptionId: null,
      inceptionDate: null,
      contractPrice: null,
      inceptionPaydown: null,
      netCap: null,
      principalPmt: null,
      interestCharge: null,
      adminCharge: null,
      gstAmount: null,
      pstAmount: null,
      currentPmtNo: null,
      principalBalance: null,
      adminBalance: null,
      buyout: null,
      offroadType: null,
      offroadValue: null,
      offroadDate: null,
      offroadBalance: null,
      totalBalance: null,
      lessSecurity: null,
      totalBalanceDue: null,

      inventoryId: null,
      condition: null,
      mileageUnit: null,
      extColour: null,
      modelYear: null,
      mileageValue: null,
      intColour: null,
      make: null,
      body: null,
      fuel: null,
      model: null,
      seatingCapacity: null,
      drivetrain: null,
      trim: null,
      numberOfDoors: null,
      transmmission: null,
      stockNumber: null,
      vinNo: null,
      origenalMarket: null,
      msrpCurrency: null,
      baseMsrp: null,
      msrpOptions: [],
      accessioreOptions: [],
      vehicleDeclearation: [null, null, null, null, null, null, null],
      vehicleDeclearationText: null,
      vehicleTitleType: null,
      appraisedVehiclePrice: null,
      appraisalNotes: null,
      payoutType: null,
      unitlDate: null,
      payoutGstRate: null,
      payoutGstAmount: null,
      payoutTotalAmount: null,
      quoteGallery: [],
      deleteImages: [],
      get_lien_customer: null,

      lienHolder: {
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      },
      sellerIdentification: {
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,

        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      },
      coOwner: [],
      priceContractType: null,
      creditIssued: null,
      gstRateVehicle: null,
      pstRateVehicle: null,
      gstAmountVehicle: null,
      pstAmountVehicle: null,
      payableBySeller: null,
      quoteNumber: null,
      completionDate: null,
      quoteDate: null,
      location: null,
      comments: null,
      beforeTax: null,
      unitlDate: null,
      gstRate: null,
      gstAmount: null,
      totalAmount: null,
      priceCalculationValues: [],
    };

    return {
      wherefrom: router.currentRoute.name,
      formShow: false,
      baseURL: store.state.app.baseURL,
      purchaseQuoteData: purchaseQuoteDataModel,
      dropAll: [],
      lienCustomerData: {},
      sellerCustomerData: {},
      coOwnerCustomerDatas: [],
      invInfo: {},
      dropInventorys: [],
      dropCustomers: [],
      msrpValueOption: null,
      msrpValueOptions: [],
      msrpPriceOption: null,
      msrpPriceOptions: [],

      accessioreValueOptions: [],
      accessioreQuantityOptions: null,

      msrpOptions: [],
      msrpOption: [],

      accessioreOption: [],

      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },

      nextTodoId: 1,

      dropdownLoading: false,
    };
  },

  methods: {
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },

    formSubmitted() {
      if (this.purchaseQuoteData.inventoryId > 0)
        if (true) {
          // {
          //   this.$refs.subSellerComponent.$refs.sellerRules.validate().then((success) => {
          this.$refs.subPriceCalculation.$refs.priceRules.validate().then((success) => {
            if (success) {
              this.$refs.subCommentsComponent.$refs.commentsRules.validate().then((success) => {
                if (success) {
                  this.saveOrUpdateQuote();

                  // if (
                  //   (this.isEmptyOrSpaces(this.purchaseQuoteData.lienHolder.sellerType) && this.isEmptyOrSpaces(this.purchaseQuoteData.lienHolder.firstName)) ||
                  //   (!this.isEmptyOrSpaces(this.purchaseQuoteData.lienHolder.sellerType) && !this.isEmptyOrSpaces(this.purchaseQuoteData.lienHolder.firstName))
                  // ) {
                  //   //CO-OWNER
                  //   var saveOk = 0;
                  //   this.purchaseQuoteData.coOwner.forEach((element) => {
                  //     if ((this.isEmptyOrSpaces(element.sellerType) && this.isEmptyOrSpaces(element.firstName)) || (!this.isEmptyOrSpaces(element.sellerType) && !this.isEmptyOrSpaces(element.firstName))) {
                  //       saveOk += 1;
                  //     } else {
                  //       this.$refs.subCoOwnerComponent.$refs.coOwnerRules.validate().then((success) => {
                  //         if (success) {
                  //         } else {
                  //           if (this.purchaseQuoteData.vehicleTitleType == 1) {
                  //             this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 4);
                  //           } else {
                  //             this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 5);
                  //           }
                  //         }
                  //       });
                  //     }
                  //   });

                  //   if (saveOk == this.purchaseQuoteData.coOwner.length) {
                  //     //apprasial
                  //     this.$refs.subAppraisalComponent.$refs.appraisalRules.validate().then((success) => {
                  //       if (success) {
                  //         this.saveOrUpdateQuote();
                  //       } else {
                  //         this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 2);
                  //       }
                  //     });

                  //     //apprasail
                  //   }
                  //   //CO-OWNER
                  // } else {
                  //   this.$refs.subLienComponent.$refs.lienRules.validate().then((success) => {
                  //     if (success) {
                  //     } else {
                  //       this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 6);
                  //     }
                  //   });
                  // }
                } else {
                  //comment
                  if (this.purchaseQuoteData.vehicleTitleType == 1) {
                    this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 6);
                  } else {
                    this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 7);
                  }
                }
              });
            } else {
              if (this.purchaseQuoteData.vehicleTitleType == 1) {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 5);
              } else {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 6);
              }
            }
          });
        } else {
          if (this.purchaseQuoteData.vehicleTitleType == 1) {
            this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 3);
          } else {
            this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 4);
          }
        }
      // });
      // }
      else {
        this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0);
      }
    },

    saveOrUpdateQuote() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        store.dispatch('testlease/updateOffroad', this.purchaseQuoteData).then((response) => {
          if (response.data != 'alreadyUpdated') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Quote Update Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            this.formShow = false;

            if (this.wherefrom != 'offroad-validation-edit') {
              router.push({ name: 'offroad-preview', params: { id: this.purchaseQuoteData.id } });
            } else {
              router.push({ name: 'offroad-preview', params: { id: this.purchaseQuoteData.id } });
            }
          } else {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please refresh the page.',
                text: '⚠️ Data has been changed during operations. ',
                icon: 'RefreshCwIcon',
                variant: 'warning',
              },
            });
          }
        });
      } else {
        store.dispatch('testlease/saveOffroad', this.purchaseQuoteData).then((response) => {
          if (response.status == 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Offroad has been created successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            setTimeout(function() {
              this.formShow = false;

              router.push({ name: 'offroad-preview', params: { id: response.data.id } });
            }, 1000);
          }
        });
      }
    },

    quoteNumberCreate() {
      store
        .dispatch('testlease/nextIdOffroad')
        .then((response) => {
          this.purchaseQuoteData.quoteNumber = Number(response.data);

          this.formShow = false;
        })
        .catch((error) => {});
    },

    getByIdQuote() {
      this.formShow = true;
      if (router.currentRoute.params.id) {
        store
          .dispatch('testlease/getOffroadById', { id: router.currentRoute.params.id })
          .then((response) => {
            if (response.data.status != 'A') {
              router.push({ name: 'offroad-preview', params: { id: router.currentRoute.params.id } });
            }
            //  this.getInventoryDropId(response.data.inventoryId);
            this.purchaseQuoteData.id = response.data.id;
            this.purchaseQuoteData.vehicleId = response.data.inventoryId;
            this.purchaseQuoteData.inventoryId = response.data.inventoryId;
            this.purchaseQuoteData.location = response.data.location;
            this.purchaseQuoteData.comments = response.data.comments;
            this.purchaseQuoteData.quoteDate = response.data.quoteDate;
            this.purchaseQuoteData.completionDate = response.data.completionDate;
            this.purchaseQuoteData.quoteNumber = response.data.id;
            this.lienCustomerData = response.data.get_lien_customer;
            this.sellerCustomerData = response.data.get_seller_customer;
            this.purchaseQuoteData.updated_at = response.data.updated_at;

            //PriceCalculation
            this.purchaseQuoteData.priceContractType = response.data.priceContractType;
            this.purchaseQuoteData.creditIssued = response.data.creditIssued;
            this.purchaseQuoteData.gstRateVehicle = response.data.gstRateVehicle;
            this.purchaseQuoteData.pstRateVehicle = response.data.pstRateVehicle;
            this.purchaseQuoteData.gstAmountVehicle = response.data.gstAmountVehicle;
            this.purchaseQuoteData.pstAmountVehicle = response.data.pstAmountVehicle;

            this.purchaseQuoteData.payableBySeller = response.data.payableBySeller;

            response.data.coOwnerList.forEach((element) => {
              this.coOwnerCustomerDatas.push(element);
            });

     
            //VehicleAppraisal & Lien
            this.purchaseQuoteData.vehicleTitleType = response.data.vehicleTitleType;
            this.purchaseQuoteData.appraisedVehiclePrice = response.data.appraisedVehiclePrice;
            this.purchaseQuoteData.appraisalNotes = response.data.appraisalNotes;
            this.purchaseQuoteData.payoutType = response.data.payoutType;
            this.purchaseQuoteData.beforeTax = response.data.beforeTax;
            this.purchaseQuoteData.unitlDate = response.data.unitlDate;
            this.purchaseQuoteData.gstRate = response.data.gstRate;

            this.purchaseQuoteData.inceptionId = response.data.inceptionId;
            this.purchaseQuoteData.inceptionDate = response.data.inceptionDate;
            this.purchaseQuoteData.contractPrice = response.data.contractPrice;
            this.purchaseQuoteData.inceptionPaydown = response.data.inceptionPaydown;
            this.purchaseQuoteData.netCap = response.data.netCap;
            this.purchaseQuoteData.principalPmt = response.data.principalPmt;
            this.purchaseQuoteData.interestCharge = response.data.interestCharge;
            this.purchaseQuoteData.adminCharge = response.data.adminCharge;
            this.purchaseQuoteData.gstAmount = response.data.gstAmount;
            this.purchaseQuoteData.pstAmount = response.data.pstAmount;
            this.purchaseQuoteData.currentPmtNo = response.data.currentPmtNo;
            this.purchaseQuoteData.principalBalance = response.data.principalBalance;
            this.purchaseQuoteData.adminBalance = response.data.adminBalance;
            this.purchaseQuoteData.buyout = response.data.buyout;
            this.purchaseQuoteData.offroadType = response.data.offroadType;
            this.purchaseQuoteData.offroadValue = response.data.offroadValue;
            this.purchaseQuoteData.offroadDate = response.data.offroadDate;
            this.purchaseQuoteData.offroadBalance = response.data.offroadBalance;
            this.purchaseQuoteData.totalBalance = response.data.totalBalance;
            this.purchaseQuoteData.lessSecurity = response.data.lessSecurity;
            this.purchaseQuoteData.totalBalanceDue = response.data.totalBalanceDue;
            //VehiclePhotos
            response.data.get_images.forEach((element) => {
              this.purchaseQuoteData.quoteGallery.push(element);
            });

            setTimeout(() => (this.formShow = false), 1000);
            // setTimeout(function() {

            //   this.formShow = false;
            // }, 2000);
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'offroad-list' });
          });
      } else {
        this.quoteNumberCreate();
      }
    },

    getAllDropDown() {
      this.formShow = true;
      store
        .dispatch('testlease/offroadAllDropDown')
        .then((response) => {
          this.dropAll = response.data;
          this.dropInventorys = response.data.inventoryDrop;
          this.dropCustomers = response.data.customerDrop;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching dropdowns list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getInventoryDropId(id) {
      if (id != null) {
        store
          .dispatch('testlease/showOffroadDrop', id)
          .then((response) => {
            //DescriptionOfVehlicle
            this.invInfo = response.data;
            this.purchaseQuoteData.vehicleId = id;
            this.purchaseQuoteData.condition = response.data.condition.charAt(0).toUpperCase() + response.data.condition.slice(1);
            this.purchaseQuoteData.mileageUnit = response.data.mileageUnit;
            this.purchaseQuoteData.extColour = response.data.extColour;
            this.purchaseQuoteData.modelYear = response.data.modelYear;
            this.purchaseQuoteData.mileageValue = response.data.mileageValue;
            this.purchaseQuoteData.intColour = response.data.intColour;
            this.purchaseQuoteData.make = response.data.make;
            this.purchaseQuoteData.body = response.data.body;
            this.purchaseQuoteData.fuel = response.data.fuel;
            this.purchaseQuoteData.model = response.data.model;
            this.purchaseQuoteData.drivetrain = response.data.drivetrain;
            this.purchaseQuoteData.trim = response.data.trim;
            this.purchaseQuoteData.transmmission = response.data.transmmission;
            this.purchaseQuoteData.vinNo = response.data.vinNo;
            this.purchaseQuoteData.stockNumber = response.data.stockNumber;
            this.purchaseQuoteData.seatingCapacity = response.data.seatingCapacity;
            this.purchaseQuoteData.numberOfDoors = response.data.numberOfDoors;

            //MSRPConfiguration
            this.purchaseQuoteData.origenalMarket = response.data.origenalMarket;
            this.purchaseQuoteData.baseMsrp = response.data.baseMsrp;
            this.purchaseQuoteData.msrpCurrency = response.data.msrpCurrency;
            this.msrpOptions = response.data.get_msrp_options;
            this.purchaseQuoteData.msrpOptions = this.msrpOptions;

            //AccesspriesRegistration
            this.accessioreOption = response.data.get_accessiore_options;
            this.purchaseQuoteData.accessioreOptions = this.accessioreOption;

            //VehicleDeclearation
            this.purchaseQuoteData.vehicleDeclearation = [response.data.vd1, response.data.vd2, response.data.vd3, response.data.vd4, response.data.vd5, response.data.vd6, response.data.vd7];
            this.purchaseQuoteData.vehicleDeclearationText = response.data.vd1Text;

            this.purchaseQuoteData.inception = response.data.inception;
            this.purchaseQuoteData.inceptionId = response.data.inception.id;

            this.purchaseQuoteData.inceptionDate = response.data.inception.quoteDate;
            this.purchaseQuoteData.contractPrice = response.data.inception.leasedPrice;
            this.purchaseQuoteData.inceptionPaydown = response.data.inception.leasedPrice - response.data.inception.netCap;
            this.purchaseQuoteData.netCap = response.data.inception.netCap;
            this.purchaseQuoteData.principalPmt = response.data.inception.principalPmt;
            this.purchaseQuoteData.interestCharge = response.data.inception.interestCharge;
            this.purchaseQuoteData.adminCharge = response.data.inception.adminCharge;

            this.purchaseQuoteData.currentPmtNo = response.data.inception.currentPmtNo;
            this.purchaseQuoteData.principalBalance = response.data.inception.principalBalance;
            this.purchaseQuoteData.adminBalance = response.data.inception.adminBalance;
            this.purchaseQuoteData.buyout = response.data.inception.buyout;

            this.purchaseQuoteData.gstRateVehicle = response.data.inception.gstRateVehicle;
            this.purchaseQuoteData.pstRateVehicle = response.data.inception.pstRateVehicle;
            this.purchaseQuoteData.lessSecurity = response.data.inception.securityDeposit;

            this.purchaseQuoteData.sellerCustomerId = response.data.inception.purchaserCustomerId;
            this.purchaseQuoteData.sellerIdentification.sellerType = response.data.inception.lesseeType;
            this.purchaseQuoteData.sellerIdentification.phoneNumber = response.data.inception.lesseePhone;
            this.purchaseQuoteData.sellerIdentification.firstName = response.data.inception.lesseeName;
            this.purchaseQuoteData.sellerIdentification.lastName = response.data.inception.lesseeLastName;
            this.purchaseQuoteData.sellerIdentification.email = response.data.inception.lesseeEmail;
            this.purchaseQuoteData.sellerIdentification.unitNumber = response.data.inception.lesseeUnit;
            this.purchaseQuoteData.sellerIdentification.provence = response.data.inception.lesseeProvince;
            this.purchaseQuoteData.sellerIdentification.streetAddress = response.data.inception.lesseeStreet;
            this.purchaseQuoteData.sellerIdentification.city = response.data.inception.lesseeCity;
            this.purchaseQuoteData.sellerIdentification.postalCode = response.data.inception.lesseePostal;
            this.purchaseQuoteData.sellerIdentification.country = response.data.inception.lesseeCountry;
            this.purchaseQuoteData.sellerIdentification.gst = response.data.inception.lesseeGST;
            this.purchaseQuoteData.sellerIdentification.pst = response.data.inception.lesseePST;
            this.purchaseQuoteData.sellerIdentification.dealer = response.data.inception.lesseeDealer;
            this.purchaseQuoteData.sellerIdentification.driverLicenseNumber = response.data.inception.lesseeLicense;
            this.purchaseQuoteData.sellerIdentification.otherIdNumber = response.data.inception.lesseeOtherId;
            this.purchaseQuoteData.sellerIdentification.dateOfBirth = response.data.inception.lesseeDateOfBirth;
            this.purchaseQuoteData.sellerIdentification.contactPerson = response.data.inception.lesseeContactPerson;
            this.purchaseQuoteData.sellerIdentification.contactInfo = response.data.inception.lesseeContactInfo;
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching inventory',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.clearInventoryData();
      }
    },

    clearInventoryData() {
      //CLEAR INVENTORY MODEL
      this.purchaseQuoteData.condition = null;
      this.purchaseQuoteData.mileageUnit = null;
      this.purchaseQuoteData.extColour = null;
      this.purchaseQuoteData.modelYear = null;
      this.purchaseQuoteData.mileageValue = null;
      this.purchaseQuoteData.intColour = null;
      this.purchaseQuoteData.make = null;
      this.purchaseQuoteData.body = null;
      this.purchaseQuoteData.fuel = null;
      this.purchaseQuoteData.model = null;
      this.purchaseQuoteData.seatingCapacity = null;
      this.purchaseQuoteData.drivetrain = null;
      this.purchaseQuoteData.trim = null;
      this.purchaseQuoteData.numberOfDoors = null;
      this.purchaseQuoteData.transmmission = null;
      this.purchaseQuoteData.stockNumber = null;
      this.purchaseQuoteData.vinNo = null;
      this.purchaseQuoteData.origenalMarket = null;
      this.purchaseQuoteData.msrpCurrency = null;
      this.purchaseQuoteData.baseMsrp = null;
      this.purchaseQuoteData.msrpOptions = [];
      this.purchaseQuoteData.accessioreOptions = [];
      this.purchaseQuoteData.vehicleDeclearation = [null, null, null, null, null, null, null];
      this.purchaseQuoteData.vehicleDeclearationText = null;
      this.purchaseQuoteData.priceContractType = null;
      this.purchaseQuoteData.gstRateVehicle = null;
      this.purchaseQuoteData.pstRateVehicle = null;
      this.purchaseQuoteData.gstAmountVehicle = null;
      this.purchaseQuoteData.pstAmountVehicle = null;
      this.purchaseQuoteData.priceCalculationValues = [];
      this.purchaseQuoteData.offroadType = null;
      this.purchaseQuoteData.offroadValue = null;
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    getInventoryDrops() {
      store
        .dispatch('testlease/getInventoryDropDowns')
        .then((response) => {
          response.data.forEach((element) => {
            if (element.value != null) {
              this.dropInventorys.push(element);
            }
          });
        })
        .catch((error) => {});
    },

    getCustomerDrops() {
      store
        .dispatch('testlease/getCustomerDropDowns')
        .then((response) => {
          this.dropCustomers = response.data;
        })
        .catch((error) => {});
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.add-new-dropdown {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
    cursor: pointer;
  }
}
</style>
