<template>
  <div>
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Accessories Registration</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" md="12">
        <b-row>
          <b-col cols="12" md="7">
            <!--  <b-form-input v-model="msrpOption.title" id="msrpTitle" required placeholder="Msrp Options" /> !-->
            <b-form-group label="Accessorie Options Name" label-for="accessiore-option">
              <v-select
                v-model="accessioreValueOptions.id"
                :taggable="true"
                :create-option="(temp) => ({ id: 0, option: temp, who: 'accessioreValueOptions' })"
                :loading="dropdownLoading"
                @option:created="createNewDropdownValue"
                @option:selected="selectedDropdownValue"
                :disabled="!(purchaseQuoteData.make > 0 && purchaseQuoteData.model > 0)"
                :value="accessioreValueOptions.option"
                :reduce="(val) => val.id"
                :options="accessioreValueOptions"
                label="option"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="10" md="4">
            <b-form-group label="Quantity" label-for="quantity">
              <vue-numeric-input v-model="accessioreQuantityOptions" :min="0" controlsType="plusminus" :max="100" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem" :step="1"></vue-numeric-input>
            </b-form-group>
          </b-col>

          <b-col cols="1" md="1" class="text-center">
            <b-form-group label="#" label-for="quantity">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="accessioreQuantityOptions == null || accessioreQuantityOptions == '' || !accessioreValueOptions.id" @click="addAccessiore()" class="btn-icon text-center" variant="primary">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" md="12" class="pt-2 mb-5">
        <table class="table border table-sm" v-if="purchaseQuoteData.accessioreOptions.length > 0">
          <thead>
            <tr>
              <th scope="col" class="text-left">Accessorie Name</th>
              <th scope="col" class=" text-center">Quantity</th>
              <th scope="col" class="text-center" style="width:10%">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in purchaseQuoteData.accessioreOptions" v-bind:key="item.index">
              <td>{{ item.title }}</td>
              <td class="text-center">{{ item.quantity }}</td>

              <td class="text-center"><feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeAccessioreItem(index)" size="16" class="cursor-pointer mx-1" /></td>
            </tr>
          </tbody>
        </table>

        <b-alert variant="warning" :show="purchaseQuoteData.accessioreOptions == null || purchaseQuoteData.accessioreOptions == ''">
          <div class="alert-body">
            <span
              ><strong><center>Your Accessories Registration is empty.</center></strong>
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton, BAlert } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import { isObject } from '@vueuse/shared';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,

    vSelect,
    VueNumericInput,
    ToastificationContent,
    Cleave,

    //validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
    accessioreOption: {
      type: Array,
      required: false,
    },

    dropAll: {
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      accessioreValueOptions: [],

      accessioreQuantityOptions: null,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {
    'purchaseQuoteData.model': {
      handler: function(id, before) {
        if (id != null) {
          this.accessoriesClick();
        } else {
          this.purchaseQuoteData.accessioreOptions = [];
          this.$emit('update:accessioreOption', []);
        }
      },
    },
  },

  computed: {},

  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      switch (val.who) {
        case 'accessioreValueOptions':
          if (this.accessioreValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveAccessioreOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.purchaseQuoteData.make,
                modelId: this.purchaseQuoteData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.accessioreValueOptions.unshift(response.data);
                  this.accessioreValueOptions.id = response.data.id;
                  this.toastMessage('Accessorie Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        default:
          break;
      }
    },

    selectedDropdownValue(val) {
      switch (val.who) {
        case 'accessioreValueOptions':
          this.accessioreValueOptions.id = this.accessioreValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          break;

        default:
          break;
      }
    },

    accessoriesClick() {
      if (this.purchaseQuoteData.make) {
        var a = this.purchaseQuoteData.make.id;
        var b = this.purchaseQuoteData.model.id;
      }

      if (this.purchaseQuoteData.make.id == undefined) {
        a = this.purchaseQuoteData.make;
      }
      if (this.purchaseQuoteData.model.id == undefined) {
        b = this.purchaseQuoteData.model;
      }

      store
        .dispatch('purchase/getAccessioreOption')
        .then((response) => {
          this.accessioreValueOptions = response.data.filter((x) => x.makeId == a && x.modelId == b);

          if (this.accessioreValueOptions.filter((x) => x.option == 'KEY').length == 0) {
            this.accessioreValueOptions.unshift({
              id: 99999,
              option: 'KEY',
              makeId: this.purchaseQuoteData.make,
              modelId: this.purchaseQuoteData.model,
            });
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching price list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    addAccessiore() {
      //  this.accessiores.

      if (isObject(this.accessioreValueOptions.id)) {
        this.accessioreValueOptions.id = this.accessioreValueOptions.id.id;
      }

      this.accessioreOption.push({
        title: this.accessioreValueOptions.filter((x) => x.id == this.accessioreValueOptions.id)[0].option,
        quantity: this.accessioreQuantityOptions,
        modelId: this.purchaseQuoteData.make,
        makeId: this.purchaseQuoteData.model,
      });

      this.purchaseQuoteData.accessioreOptions = this.accessioreOption;

      this.accessioreQuantityOptions = null;
      this.accessioreValueOptions.id = null;
    },

    removeAccessioreItem(e) {
      this.$delete(this.accessioreOption, e);
      this.purchaseQuoteData.accessioreOptions = this.accessioreOption;
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
