<template>
  <validation-observer ref="commentsRules">
    <div>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Comments, Promises, Restrictions, Limitations and Conditions</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="vinNo">Quote Number</label>
            <b-form-input id="vinNo" disabled v-model="purchaseQuoteData.quoteNumber" type="text" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Expected Completion Date" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
              <label for="vinNo">Expected Completion Date*</label>
              <flat-pickr id="quotationDate" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="purchaseQuoteData.completionDate" />
              <small class="text-danger" v-if="purchaseQuoteData.completionDate == null && wherefrom == 'quote-validation-edit'">The Expected Completion Date is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Quote Date" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
              <label for="quotationDate">Quote Date*</label>
              <flat-pickr id="quotationDate" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="purchaseQuoteData.quoteDate" />
              <small class="text-danger" v-if="purchaseQuoteData.quoteDate == null && wherefrom == 'quote-validation-edit'">The Quote Date is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Quote Date" :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
              <label for="location">Expected Receiving Location</label>
              <b-form-input id="location" v-model="purchaseQuoteData.location" type="text" />
              <small class="text-danger" v-if="purchaseQuoteData.location == null && wherefrom == 'quote-validation-edit'">The Expected Receiving Location is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <label for="textarea-default">Comments, Promises, Restrictions, Limitations and Conditions</label>
          <b-form-textarea v-model="purchaseQuoteData.comments" id="textarea-default" rows="5" />
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormTextarea,

    //validation
    ValidationProvider,
    ValidationObserver,

    ToastificationContent,
    flatPickr,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,
    };
  },

  watch: {},

  computed: {},

  methods: {},
};
</script>
