var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"declerationRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-row',[_c('table',{staticClass:"table   table-sm"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"90%"},attrs:{"scope":"col"}},[_vm._v("Vehicle Declaration*")]),_c('th',{staticStyle:{"width":"5%"},attrs:{"scope":"col"}},[_vm._v("Yes")]),_c('th',{staticStyle:{"width":"5%"},attrs:{"scope":"col"}},[_vm._v("No")])])])]),_c('table',{staticClass:"table table-responsive"},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"90%"}},[_vm._v(" 1. Is the motor vehicle complies with the requirements of the Motor Vehicle Act? "),_c('br'),_vm._v(" If no, the vehicle is sold for parts only or for purposes other than transportation, and this fact has been disclosed to the Purchaser. ")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 1.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',{staticClass:"text-center",staticStyle:{"width":"5%"}},[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-one","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[0]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 0, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[0]"}})],1),_c('td',{staticClass:"text-center",staticStyle:{"width":"5%"}},[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-one","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[0]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 0, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[0]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[0] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v("2. Has the motor vehicle ever been used as a taxi, police vehicle, emergency vehicle or used in organized racing?")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 2.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-two","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[1]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 1, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[1]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-two","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[1]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 1, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[1]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[1] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v("3. Has the motor vehicle ever been used as a lease or rental vehicle?")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 3.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-three","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[2]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 2, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[2]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-three","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[2]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 2, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[2]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[2] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v("4. Has the motor vehicle ever sustained damage requiring repairs costing more than $2000.00?")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 4.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-four","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[3]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 3, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[3]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-four","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[3]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 3, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[3]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[3] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v(" 5. Has the motor vehicle ever been registered in any other jurisdiction other than British Columbia? "),_c('br'),_c('b-input-group',{staticClass:"mt-1",attrs:{"size":"sm","prepend":"If 'Yes', in what jurisdiction(s) has the vehicle been registered: "}},[_c('b-form-input',{model:{value:(_vm.purchaseQuoteData.vehicleDeclearationText),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData, "vehicleDeclearationText", $$v)},expression:"purchaseQuoteData.vehicleDeclearationText"}})],1)],1),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 5.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-five","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[4]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 4, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[4]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-five","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[4]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 4, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[4]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[4] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v("6. Has the Vehicle been brought into British Columbia specifically for the purpose of resale?")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 6.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-six","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[5]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 5, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[5]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-six","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[5]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 5, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[5]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[5] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1),_c('tr',[_c('td',[_vm._v("7. The odometer of the vehicle accurately records the ture distance traveled by the motor vehicle to the best of the dealer's knowledge and belief.")]),_c('validation-provider',{attrs:{"name":"Vehicle Declaration 7.","rules":_vm.wherefrom == 'quote-validation-edit' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-seven","value":"true"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[6]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 6, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[6]"}})],1),_c('td',[_c('b-form-radio',{staticStyle:{"transform":"scale(1.5)"},attrs:{"name":"some-seven","value":"false"},model:{value:(_vm.purchaseQuoteData.vehicleDeclearation[6]),callback:function ($$v) {_vm.$set(_vm.purchaseQuoteData.vehicleDeclearation, 6, $$v)},expression:"purchaseQuoteData.vehicleDeclearation[6]"}})],1),(_vm.purchaseQuoteData.vehicleDeclearation[6] == null && _vm.wherefrom == 'quote-validation-edit')?_c('small',{staticClass:"text-danger"},[_vm._v("One option is required ")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }