<template>
  <validation-observer ref="declerationRules">
    <b-row>
      <b-col cols="12" md="12">
        <b-row>
          <table class="table   table-sm">
            <thead>
              <tr>
                <th scope="col" style="width: 90%;">Vehicle Declaration*</th>
                <th scope="col" style="width: 5%;">Yes</th>
                <th scope="col" style="width: 5%;">No</th>
              </tr>
            </thead>
          </table>

          <table class="table table-responsive">
            <tbody>
              <tr>
                <td style="width: 90%;">
                  1. Is the motor vehicle complies with the requirements of the Motor Vehicle Act? <br />
                  If no, the vehicle is sold for parts only or for purposes other than transportation, and this fact has been disclosed to the Purchaser.
                </td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 1." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td style="width: 5%;" class="text-center"><b-form-radio name="some-one" v-model="purchaseQuoteData.vehicleDeclearation[0]" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td style="width: 5%;" class="text-center"><b-form-radio name="some-one" v-model="purchaseQuoteData.vehicleDeclearation[0]" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[0] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>2. Has the motor vehicle ever been used as a taxi, police vehicle, emergency vehicle or used in organized racing?</td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 2." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[1]" name="some-two" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[1]" name="some-two" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[1] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>3. Has the motor vehicle ever been used as a lease or rental vehicle?</td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 3." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[2]" name="some-three" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[2]" name="some-three" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[2] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>4. Has the motor vehicle ever sustained damage requiring repairs costing more than $2000.00?</td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 4." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[3]" name="some-four" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[3]" name="some-four" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[3] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>
                  5. Has the motor vehicle ever been registered in any other jurisdiction other than British Columbia? <br />

                  <b-input-group class="mt-1" size="sm" prepend="If 'Yes', in what jurisdiction(s) has the vehicle been registered: ">
                    <b-form-input v-model="purchaseQuoteData.vehicleDeclearationText" />
                  </b-input-group>
                </td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 5." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[4]" name="some-five" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[4]" name="some-five" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[4] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>6. Has the Vehicle been brought into British Columbia specifically for the purpose of resale?</td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 6." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[5]" name="some-six" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[5]" name="some-six" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[5] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
              <tr>
                <td>7. The odometer of the vehicle accurately records the ture distance traveled by the motor vehicle to the best of the dealer's knowledge and belief.</td>
                <validation-provider #default="{ errors }" name="Vehicle Declaration 7." :rules="wherefrom == 'quote-validation-edit' ? 'required' : ''">
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[6]" name="some-seven" style=" transform: scale(1.5);" value="true"> </b-form-radio></td>
                  <td><b-form-radio v-model="purchaseQuoteData.vehicleDeclearation[6]" name="some-seven" style=" transform: scale(1.5);" value="false"> </b-form-radio></td>
                  <small class="text-danger" v-if="purchaseQuoteData.vehicleDeclearation[6] == null && wherefrom == 'quote-validation-edit'">One option is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </tr>
            </tbody>
          </table>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,

    //validation
    ValidationProvider,
    ValidationObserver,

    VueNumericInput,
    ToastificationContent,
    Cleave,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {},

  computed: {},

  methods: {},
};
</script>
